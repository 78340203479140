import React, { useState, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk'
import {
  Root,
  BrandingTitle,
  FormContainer,
  FormFieldsContainer,
  BrandingPlaceholder,
  TermsTypography
} from './styledComponents'
import { Typography, Link } from '@mui/material'
import { Auth } from 'aws-amplify'
import { setDeviceKeyExpiration } from '@utils/deviceKey'

import { MfaSetupModal, MfaLoginModal } from '@components/authentication/mfa'
import { useAlert } from '@hooks'
import { isDomainAllowed, logger } from '@utils'
import { CHALLENGE_NAMES } from '@components/authentication/constants'
import { useTheme } from '@theme'
import { NewPasswordRequiredForm } from './NewPasswordRequiredForm'
import { NormalLoginForm } from './NormalLoginForm'
import { SAMLLoginForm } from './SAMLLoginForm'

const isDevEnv = process.env.NODE_ENV !== 'production'
const redirectToPartnerPortal = (isDevEnv, isContinue, redirectUrl) => {
  if (isContinue && redirectUrl && isDomainAllowed(redirectUrl)) {
    window.location.replace(redirectUrl)
    return
  }
  if (isDevEnv) {
    window.location.replace(process.env.REACT_APP_PARTNER_PORTAL)
  } else {
    window.location.reload()
  }
}

const LoginPage = (props) => {
  const [user, setUser] = useState(null)
  const [mfaModalOpen, setMfaModalOpen] = useState(false)
  const [mfaLoginOpen, setMfaLoginOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [credentials, setCredentials] = useState({}) // in case user need to resend the verification code.
  const { search } = useLocation()
  const ldclient = useLDClient()
  const alert = useAlert()
  const themeContext = useTheme()
  const { useTermsPolicy } = useFlags()

  // SAML test
  const isSamlDomain = themeContext.samlProvider

  useEffect(() => {
    // currently it will redirect back, since no user record on our system
    const userRedirect = async () => {
      try {
        const session = await Auth.currentSession()
        if (session) {
          if (isDevEnv) {
            window.location.replace(process.env.REACT_APP_PARTNER_PORTAL)
          } else window.location.reload()
        }
      } catch (e) {
        console.log(e)
      }
    }
    const initializeLDClient = async () => {
      try {
        await ldclient.identify({
          anonymous: true,
          custom: {
            domain: window.location.hostname
          }
        })
      } catch (error) {}
    }
    userRedirect()
    initializeLDClient()
  }, [ldclient])

  useEffect(() => {
    if (props.user) setUser(props.user)
  }, [props.user])

  const handleRedirect = useCallback(() => {
    if (search) {
      const param = new window.URLSearchParams(search)
      if (param.has('continue')) {
        const redirectUrl = param.get('continue')
        redirectToPartnerPortal(isDevEnv, true, redirectUrl)
      } else {
        redirectToPartnerPortal(isDevEnv)
      }
    } else {
      redirectToPartnerPortal(isDevEnv)
    }
  }, [search])

  const handleLogin = async (username, password) => {
    setLoading(true)
    await Auth.signOut()
    try {
      const user = await Auth.signIn(username, password)
      setUser(user)
      const hasMfaSetup = user.preferredMFA !== 'NOMFA'
      // LD identify
      const flags = await ldclient.identify({
        key: username,
        email: username
      })
      const forceMfaSetupFlag = flags.forceMFASetup
      if (!CHALLENGE_NAMES[user.challengeName]) {
        if (!hasMfaSetup && forceMfaSetupFlag) {
          setMfaModalOpen(true)
        } else {
          alert({ status: 'success', message: 'Successful login, welcome!' })
          setDeviceKeyExpiration(user)
          handleRedirect()
        }
      } else {
        if (user.challengeName !== CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
          setCredentials({ username, password })
          setMfaLoginOpen(true)
        }
      }
    } catch (e) {
      alert({ status: 'error', message: 'Incorrect username or password' })
    } finally {
      setLoading(false)
    }
  }

  const handleMfaSetupModalOpen = () => {
    setUser(null)
    setMfaModalOpen((open) => {
      // close modal will clear out user session
      if (open) Auth.signOut()
      return !open
    })
  }

  const handleMfaLoginModalOpen = () => {
    setUser(null)
    setMfaLoginOpen((open) => !open)
  }

  const handleOpenLinks = (url) => () => window.open(url)

  const completeNewPassword = async (fields) => {
    // LD identify
    const email = user.challengeParam?.userAttributes?.email.toLowerCase()
    const flags = await ldclient.identify({
      key: email,
      email
    })
    const forceMfaSetupFlag = flags.forceMFASetup

    try {
      const finishSignup = await Auth.completeNewPassword(
        user,
        fields.new_password,
        {
          given_name: fields.first_name,
          family_name: fields.last_name
        }
      )

      if (!finishSignup.challengeName) {
        if (forceMfaSetupFlag) setMfaModalOpen(true)
        else {
          alert({ status: 'success', message: 'Successful login, welcome!' })
          setDeviceKeyExpiration(user)
          handleRedirect()
        }
      }
    } catch (error) {
      // sentry error for mfa setup
      if (forceMfaSetupFlag) {
        logger.error(error.message)
      }
      alert({ status: 'error', message: error.message })
    }
  }

  const isNewPasswordRequired =
    user?.challengeName === CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED

  return (
    <Root>
      <BrandingTitle>
        <BrandingPlaceholder>
          {themeContext.logo
            ? (
              <img src={themeContext.logo} alt={themeContext.displayName || ''} />
              )
            : (
              <Typography variant='h5'>
                {themeContext.displayName || ''}
              </Typography>
              )}
        </BrandingPlaceholder>
      </BrandingTitle>
      <FormContainer>
        <Typography variant='h3'>Log In</Typography>
        <FormFieldsContainer>
          {isNewPasswordRequired
            ? (
              <NewPasswordRequiredForm
                completeNewPassword={completeNewPassword}
              />
              )
            : isSamlDomain
              ? (
                <SAMLLoginForm />
                )
              : (
                <NormalLoginForm login={handleLogin} loading={loading} />
                )}
        </FormFieldsContainer>
      </FormContainer>
      {useTermsPolicy && (
        <TermsTypography variant='body2'>
          By selecting{' '}
          {isNewPasswordRequired
            ? 'Set Up Two-Factor Authentication'
            : 'Log In'}
          , you agree to the{' '}
          <Link
            onClick={handleOpenLinks(
              `${process.env.PUBLIC_URL}/static/legal/index.html`
            )}
            target='_blank'
            rel='noreferrer'
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            onClick={handleOpenLinks(
              `${process.env.PUBLIC_URL}/static/legal/index.html#privacy`
            )}
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy
          </Link>
        </TermsTypography>
      )}
      <MfaSetupModal
        open={mfaModalOpen}
        user={user}
        handleModalOpen={handleMfaSetupModalOpen}
        handleSuccessLogin={handleRedirect}
      />
      <MfaLoginModal
        open={mfaLoginOpen}
        user={user}
        handleModalOpen={handleMfaLoginModalOpen}
        handleSuccessLogin={handleRedirect}
        credentials={credentials}
        setUser={setUser}
      />
    </Root>
  )
}

export default LoginPage
