import React, { useState } from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import { Link, Typography } from '@mui/material'
import { useFormField, useAlert } from '@hooks'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ValidationTextField } from '@common'
import { passwordPatternRegex } from '@utils'
import { useTheme } from '@theme'

import {
  Root,
  BrandingTitle,
  FormContainer,
  FormFieldsContainer,
  LinkContainer,
  AuthButton,
  EmailValidationInput,
  EmailInput,
  BrandingPlaceholder,
  ReturnButton
} from './styledComponents'

const RESET_PASSWORD_SCHEMA = yup.object().shape({
  username: yup
    .string()
    .email('Email address must be valid')
    .required('Email address is required'),
  newPassword: yup
    .string()
    .required('Password Required.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(
      passwordPatternRegex,
      'Must contain at least one uppercase, one lowercase, one number and one special case character'
    ),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  code: yup.string().required('Confirmation code required.')
})

const ResetPassword = () => {
  const [requestSendout, setRequestSendout] = useState(false)
  const themeContext = useTheme()

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(RESET_PASSWORD_SCHEMA)
  })

  const alert = useAlert()
  const history = useHistory()
  const [fields, setFields] = useFormField({
    usernameInput: ''
  })
  const { resetPasswordV2 } = useFlags()

  const handleResetRequest = async () => {
    const emailAddress = fields.usernameInput.toLowerCase()

    try {
      if (resetPasswordV2) {
        // new reset password endpoint
        const AUTH_URL = `${process.env.REACT_APP_AUTHENTICATION__URL}/reset`
        await axios.post(AUTH_URL, { emailAddress })
      } else {
        await Auth.forgotPassword(emailAddress)
        alert({
          message:
            'Confirmation code has been sent to your email, please check',
          status: 'success'
        })
      }
      setRequestSendout(true)
    } catch (error) {
      alert({ message: error.message, status: 'error' })
    }
  }

  const handlePasswordReset = async (fields) => {
    const { username, code, newPassword } = fields
    try {
      await Auth.forgotPasswordSubmit(username.toLowerCase(), code, newPassword)
      alert({
        message: 'Password has been reset!',
        status: 'success'
      })
      history.push('/')
    } catch (error) {
      alert({ message: error.message, status: 'error' })
    }
  }

  return (
    <Root>
      <BrandingTitle>
        <BrandingPlaceholder>
          {themeContext.logo
            ? (
              <img src={themeContext.logo} alt={themeContext.displayName || ''} />
              )
            : (
              <Typography variant='h5'>
                {themeContext.displayName || ''}
              </Typography>
              )}
        </BrandingPlaceholder>
      </BrandingTitle>

      <FormContainer>
        <Typography variant='h3'>Forgot Password?</Typography>
        <FormFieldsContainer>
          {!requestSendout && (
            <>
              <Typography>
                Enter your email address. If a matching account is found, we’ll
                send you a link to reset your password.
              </Typography>
              <EmailInput
                fullWidth
                label='Email Address'
                id='usernameInput'
                variant='outlined'
                onChange={setFields}
                data-cy='usernameInput'
              />
              <AuthButton
                variant='contained'
                color='secondary'
                fullWidth
                onClick={handleResetRequest}
                data-cy='resetPasswordButton'
                id='resetPasswordButton'
              >
                Reset Password
              </AuthButton>
              <LinkContainer>
                <Link
                  component={RouterLink}
                  variant='body2'
                  to='/'
                  data-cy='returnToLoginButton'
                  id='returnToLoginButton'
                >
                  Return to the Log In Page
                </Link>
              </LinkContainer>
            </>
          )}
          {requestSendout && resetPasswordV2 && (
            <>
              <Typography>
                Check your email for instructions to reset your password.
              </Typography>
              <ReturnButton
                component={RouterLink}
                variant='contained'
                color='secondary'
                fullWidth
                to='/'
              >
                Return to the Log In Page
              </ReturnButton>
            </>
          )}
          {requestSendout && !resetPasswordV2 && (
            <>
              <Typography>
                Enter your new password and verification code to reset. If you
                did not receive the verification code, contact your Admin.
              </Typography>
              <form onSubmit={handleSubmit(handlePasswordReset)}>
                <EmailValidationInput
                  fieldConfig={{ name: 'username', label: 'Email Address' }}
                  control={control}
                  error={errors.username}
                  data-cy='username'
                  id='username'
                />
                <ValidationTextField
                  fieldConfig={{ name: 'newPassword', label: 'New Password' }}
                  control={control}
                  type='password'
                  error={errors.newPassword}
                  data-cy='newPasswordInput'
                  id='newPasswordInput'
                />
                <ValidationTextField
                  fieldConfig={{
                    name: 'confirmedPassword',
                    label: 'Confirm Password'
                  }}
                  control={control}
                  type='password'
                  error={errors.confirmedPassword}
                  data-cy='confirmPasswordInput'
                  id='confirmPasswordInput'
                />
                <ValidationTextField
                  fieldConfig={{ name: 'code', label: 'Code' }}
                  control={control}
                  error={errors.code}
                  data-cy='resetVerificationCodeInput'
                  id='resetVerificationCodeInput'
                />
                <AuthButton
                  variant='contained'
                  color='secondary'
                  fullWidth
                  type='submit'
                >
                  Reset Password
                </AuthButton>
                <LinkContainer>
                  <Link
                    component={RouterLink}
                    variant='body2'
                    to='/'
                    data-cy='returnToLoginButton'
                    id='returnToLoginButton'
                  >
                    Return to the Log In Page
                  </Link>
                </LinkContainer>
              </form>
            </>
          )}
        </FormFieldsContainer>
      </FormContainer>
    </Root>
  )
}

export default ResetPassword
