import { useState, useEffect } from 'react'
import axios from 'axios'

function useFetch (url, defaultValue) {
  const [data, setData] = useState(defaultValue)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const fetchUrl = async () => {
      try {
        const response = await axios.get(url)
        const { status, data, error } = response
        if (status === 200) {
          setData(data)
          setError(null)
        } else {
          setError(error)
        }
      } catch (e) {
        setError(e)
      }
      setLoading(false)
    }

    fetchUrl()
  }, [url])

  return [data, loading, error]
}

export { useFetch }
