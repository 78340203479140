/**
 * @file extend this file to add theming to project
 */

import tinycolor from 'tinycolor2'

const PRIMARY_COLOR = '#01435D'
const SECONDARY_COLOR = '#228477'
const TERTIARY_COLOR = '#404040'
const WHITE_COLOR = '#FFF'
const GREY_COLOR = '#DCDFEA'

const defaultTheme = {
  logoUrl: '',
  favIconUrl: '',
  brandName: '',
  palette: {
    primaryColor: PRIMARY_COLOR,
    secondaryColor: SECONDARY_COLOR,
    tertiaryColor: TERTIARY_COLOR
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1rem',
        padding: '0.21875rem 1rem',
        textTransform: 'capitalize'
      },
      primary: {
        backgroundColor: PRIMARY_COLOR,
        color: WHITE_COLOR
      },
      containedPrimary: {
        backgroundColor: PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.85).toString()
        },
        '&:active': {
          backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.7).toString()
        },
        '&:disabled': {
          backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.3).toString(),
          color: WHITE_COLOR
        }
      },
      secondary: {
        backgroundColor: SECONDARY_COLOR,
        color: WHITE_COLOR
      },
      containedSecondary: {
        backgroundColor: SECONDARY_COLOR,
        '&:hover': {
          backgroundColor: tinycolor(SECONDARY_COLOR).setAlpha(0.85).toString()
        },
        '&:active': {
          backgroundColor: tinycolor(SECONDARY_COLOR).setAlpha(0.7).toString()
        },
        '&:disabled': {
          backgroundColor: tinycolor(SECONDARY_COLOR).setAlpha(0.3).toString(),
          color: WHITE_COLOR
        }
      }
    }
  },
  error: '#f44336',
  partner: '5eeded005041525400000001'
}

const defaultOverrides = {
  MuiButton: {
    root: {
      fontSize: '1rem',
      padding: '0.15625rem 1rem',
      textTransform: 'capitalize',
      marginLeft: '16px'
    },
    outlined: {
      backgroundColor: WHITE_COLOR,
      border: `1px solid ${GREY_COLOR}`,
      padding: '0.15625rem 1rem'
    },
    outlinedPrimary: {
      backgroundColor: WHITE_COLOR,
      border: `1px solid ${PRIMARY_COLOR}`,
      padding: '0.15625rem 1rem'
    }
  }
}

export { defaultTheme, defaultOverrides }
