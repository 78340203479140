import React, { useMemo, useEffect, useContext } from 'react'
import { ThemeProvider, ThemeContext } from 'styled-components'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
  StyledEngineProvider,
  adaptV4Theme
} from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import { useFetch } from '@hooks'
import { AppLoader } from '@common'
import { defaultTheme, defaultOverrides } from '@theme'
import { Amplify } from 'aws-amplify'

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error(
      'useBranding must be used within a BrandingProvider(ThemeProvider)'
    )
  }
  return context
}

/**
  theme : {logoURL,faviconURL,primaryColor,secondaryColor}
 */
export const AppThemeProvider = ({ children }) => {
  const THEME_URL = `${process.env.REACT_APP_THEME_URL}/v1/current`
  const [themeResponse, themeLoading] = useFetch(THEME_URL, {
    data: defaultTheme
  })
  const theme = themeResponse.data
  const appTheme = useMemo(() => {
    // set the partner id to root
    const revertedMUITheme = {
      ...theme,
      palette: {
        primary: { main: '#1182AC' },
        secondary: { main: '#228477' },
        tertiary: { main: theme.palette.tertiaryColor }
      },
      overrides: defaultOverrides
    }
    const customTheme = createTheme(adaptV4Theme(revertedMUITheme))
    const MUITheme = responsiveFontSizes(customTheme)
    // theme for styled component theme system
    const styledTheme = {
      main: '#01435D',
      error: theme.error,
      logo: theme.logoUrl,
      displayName: theme.displayName,
      favicon: theme.favIconUrl,
      brandName: theme.brandName,
      samlProvider: theme.samlProvider,
      samlEmailDomain: theme.samlEmailDomain,
      ...customTheme
    }
    return { MUITheme, styledTheme }
  }, [theme])

  useEffect(() => {
    // provide different domain for SAML authentication
    const currentDomain = window.location.origin
    Amplify.configure({
      Auth: {
        oauth: {
          domain: process.env.REACT_APP_CLIENT_DOMAIN,
          scope: ['email', 'openid'],
          redirectSignIn: currentDomain,
          redirectSignOut: currentDomain,
          responseType: 'code'
        }
      }
    })
  }, [theme])

  if (themeLoading) return <AppLoader />

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Welcome to the Portal</title>
        <link rel='icon' href={theme.favIconUrl} type='image/png' />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={appTheme.MUITheme}>
          <ThemeProvider theme={appTheme.styledTheme}>{children}</ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </>
  )
}
