import Cookies from 'js-cookie'

const COGINITO_COOKIE_PREFIX = 'CognitoIdentityServiceProvider'
const DEVICE_KEY = 'deviceKey'
const DEVICE_GROUP_KEY = 'deviceGroupKey'
const RANDOM_PASSWORD_KEY = 'randomPasswordKey'
const DEVICE_KEY_EXPIRATION = 'deviceKeyExpiration'
const NUMBER_OF_ALLOWED_USERS = 2

export const overwriteDeviceKey = (user) => {
  if (!user) return

  // set(overwrite) device keys in 30 days
  const deviceKeyPrefix = `${user.keyPrefix}.${user.username}.${DEVICE_KEY}`
  const deviceGroupPrefix = `${user.keyPrefix}.${user.username}.${DEVICE_GROUP_KEY}`
  const randomPasswordPrefix = `${user.keyPrefix}.${user.username}.${RANDOM_PASSWORD_KEY}`
  const randomPassword = Cookies.get(randomPasswordPrefix)
  Cookies.set(deviceKeyPrefix, user.deviceKey, { expires: 30, path: '/' })
  Cookies.set(deviceGroupPrefix, user.deviceGroupKey, {
    expires: 30,
    path: '/'
  })
  Cookies.set(randomPasswordPrefix, randomPassword, { expires: 30, path: '/' })
}

// use this cookie to track the latest 2 users' device keys
export const setDeviceKeyExpiration = (user) => {
  if (user && user.username) {
    const prefix = `${COGINITO_COOKIE_PREFIX}.${process.env.REACT_APP_APP_CLIENT_ID}.${user.username}`
    const key = `${prefix}.${DEVICE_KEY_EXPIRATION}`
    Cookies.set(key, new Date().toISOString(), { expires: 30, path: '/' })
  }

  // clearoutDeviceKey()
}

// clean up the latest NUMBER_OF_ALLOWED_USERS device related keys
export const clearoutDeviceKey = () => {
  const cookies = Object.entries(Cookies.get()).filter(([name]) =>
    name.endsWith(DEVICE_KEY_EXPIRATION)
  )

  const sortedCookies = cookies.sort(([, cookieA], [, cookieB]) => {
    const expiresA = new Date(cookieA).getTime()
    const expiresB = new Date(cookieB).getTime()
    return expiresB - expiresA
  })

  // keep the latest 2 users' device keys
  const cookiesToClear = sortedCookies.slice(NUMBER_OF_ALLOWED_USERS)

  const userIds = cookiesToClear.map(([name]) => name.split('.')[2])

  userIds.forEach((userId) => {
    const prefix = `${COGINITO_COOKIE_PREFIX}.${process.env.REACT_APP_APP_CLIENT_ID}.${userId}`
    const deviceKey = `${prefix}.${DEVICE_KEY}`
    const deviceGroup = `${prefix}.${DEVICE_GROUP_KEY}`
    const randomPassword = `${prefix}.${RANDOM_PASSWORD_KEY}`
    const deviceExpiration = `${prefix}.${DEVICE_KEY_EXPIRATION}`
    Cookies.remove(deviceKey, { path: '/' })
    Cookies.remove(deviceGroup, { path: '/' })
    Cookies.remove(randomPassword, { path: '/' })
    Cookies.remove(deviceExpiration, { path: '/' })
  })
}
