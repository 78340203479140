import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  ModalToolbarContainer,
  ModalTitleContainer,
  StyledIconButton,
  ModalTopbar
} from '@common/styles'

export const ModalTitle = ({ handleModalToggle, title }) => {
  return (
    <ModalTopbar elevation={0}>
      <ModalToolbarContainer>
        <ModalTitleContainer style={{ flexGrow: 1 }}>
          {title}
        </ModalTitleContainer>
        <StyledIconButton
          edge='start'
          color='inherit'
          onClick={handleModalToggle}
          aria-label='close'
          size='medium'
        >
          <CloseIcon />
        </StyledIconButton>
      </ModalToolbarContainer>
    </ModalTopbar>
  )
}
