import React from 'react'

import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import App from './App'
import * as serviceWorker from './serviceWorker'
import SentryBoundary from './sentryBoundary'

import { Amplify } from 'aws-amplify'
import { config } from './aws-config'

Amplify.configure({
  Auth: {
    // mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: 30, // Cookie expire in days, keep the refresh token in 30 days
      secure: false
    }
  }
})

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    height: 100vh;

    & #root {
      height:100%;
    }
  }

`

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <SentryBoundary>
    <Router>
      <GlobalStyle />
      <App />
    </Router>
  </SentryBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
