import { useState } from 'react'

const useFormField = initialState => {
  const [fields, setValues] = useState(initialState)

  const handleValueChange = e => setValues({ ...fields, [e.target.id]: e.target.value })

  return [fields, handleValueChange]
}

export { useFormField }
