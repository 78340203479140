import React, { useState, useEffect } from 'react'
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography
} from '@mui/material'
import { Auth } from 'aws-amplify'

import { ModalTitle } from '@common'
import { MFA_METHODS, STEPS } from '@components/authentication/constants'
import {
  VerifyTOTPForm,
  TotpForm,
  SMSForm,
  VerifySMSForm
} from '@components/authentication/mfa'
import {
  MFAModalContainer,
  SelectContent,
  MFAMethodSelectContainer
} from '@components/authentication/mfa/styles'
import { logger } from '@utils'

export const MfaSetupModal = ({
  open,
  user,
  handleModalOpen,
  handleSuccessLogin
}) => {
  const [method, setMethod] = useState(MFA_METHODS.TOTP)
  const [step, setStep] = useState(STEPS.METHOD_SELECT)
  const [code, setCode] = useState(null)

  useEffect(() => {
    if (user && open) {
      Auth.setupTOTP(user).then((code) => setCode(code)).catch(error => {
        const newError = new Error(`Getting TOTP code error - ${user.emailAddress} - ${error.message}`)
        logger.error(newError)
      })
    } else {
      setStep(STEPS.METHOD_SELECT)
    }
  }, [user, open])

  const handleMethodChange = (e, value) => {
    setMethod(value)
  }
  const handleNextStep = (step) => setStep(step)
  const handleClose = () => setStep(STEPS.METHOD_SELECT)

  return (
    <MFAModalContainer
      open={open}
      onClose={handleModalOpen}
      fullWidth
      disableBackdropClick
    >
      <ModalTitle
        title='Set Up Two-Factor Authentication'
        handleModalToggle={handleModalOpen}
      />
      {step === STEPS.METHOD_SELECT && (
        <SelectContent>
          <Typography variant='subtitle1'>
            Protect your account with two-factor authentication (2FA) to ensure
            that it is really you trying to access your account. 2FA is now
            required for all users.
          </Typography>
          <MFAMethodSelectContainer>
            <Typography variant='subtitle1'>Authentication method</Typography>
            <RadioGroup defaultValue={method} onChange={handleMethodChange}>
              <FormControlLabel
                value={MFA_METHODS.TOTP}
                control={<Radio color='primary' />}
                label='Authenticator app (recommended)'
              />
              <FormControlLabel
                value={MFA_METHODS.SMS}
                control={<Radio color='primary' />}
                label='Text message (US only)'
              />
            </RadioGroup>
          </MFAMethodSelectContainer>
        </SelectContent>
      )}
      {step === STEPS.METHOD_SELECT && method === MFA_METHODS.TOTP && (
        <TotpForm
          code={code}
          handleStep={handleNextStep}
          handleClose={handleModalOpen}
        />
      )}
      {step === STEPS.METHOD_SELECT && method === MFA_METHODS.SMS && (
        <SMSForm
          user={user}
          handleStep={handleNextStep}
          handleClose={handleModalOpen}
        />
      )}
      {step === STEPS.VERIFY_TOTP && (
        <VerifyTOTPForm
          user={user}
          handleClose={handleClose}
          handleSuccessLogin={handleSuccessLogin}
        />
      )}
      {step === STEPS.VERIFY_SMS && (
        <VerifySMSForm
          user={user}
          handleClose={handleClose}
          handleSuccessLogin={handleSuccessLogin}
        />
      )}
    </MFAModalContainer>
  )
}
