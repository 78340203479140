import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import {
  Button,
  Typography,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormControl,
  InputLabel
} from '@mui/material'
import { useAlert } from '@hooks'
import { FormInput, ColoredCheckBox, ResendButton, StyledForm } from '@components/authentication/mfa/styles'
import { RESEND_ATTEMPT_KEY_SUFFIX } from '@components/authentication/constants'
import { setDeviceKeyExpiration } from '@utils/deviceKey'

const TIME_BETWEEN_SMS_TOKEN_REQUESTS = process.env.REACT_APP_TIME_BETWEEN_SMS_TOKEN_REQUESTS

export const SMSLoginForm = ({
  user,
  credentials,
  handleClose,
  handleSuccessLogin,
  setUser
}) => {
  const phone = user.challengeParam?.CODE_DELIVERY_DESTINATION
  const attemptKey = `${credentials.username}-${RESEND_ATTEMPT_KEY_SUFFIX}`
  const attempts = JSON.parse(window.localStorage.getItem(attemptKey)) || { count: 0 }
  const isAttemptLimited = attempts.count >= 6 && attempts.expiry > new Date().getTime() // initial state for resend button

  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [rememberDevice, setRememberDevice] = useState(false)
  const [disableResend, setDisableResend] = useState(isAttemptLimited)
  const alert = useAlert()

  const handleCodeChange = (e) => {
    setError('') // clear out the error message
    const value = e.target.value.replace(/[^0-9]/g, '')
    if (value.length <= 6) setCode(value)
  }
  const handleRememberDevice = (e) => setRememberDevice(e.target.checked)

  const handleVerifySms = async (e) => {
    if (loading) return
    setLoading(true)
    e.preventDefault()
    try {
      await Auth.confirmSignIn(user, code, 'SMS_MFA')
      if (rememberDevice) {
        await Auth.rememberDevice()
      }
      alert({ status: 'success', message: 'Successful login, welcome!' })
      // clear out the attempt acount
      const attemptKey = `${credentials.username}-${RESEND_ATTEMPT_KEY_SUFFIX}`
      window.localStorage.removeItem(attemptKey)
      setDeviceKeyExpiration(user)
      handleSuccessLogin()
    } catch (error) {
      setLoading(false)
      setError('The code is incorrect or expired. Verify the code and try again.')
    }
  }

  const handleResend = async () => {
    setDisableResend(true)
    // local storage count
    const currentCount = attempts.count
    const currentTime = new Date().getTime()
    if (currentCount >= process.env.REACT_APP_SMS_ATTEMPT_LIMIT && attempts.expiry > currentTime) {
      alert({ status: 'error', message: 'You have reached the maximum number of code requests. Please try again in one hour.' })
    } else {
      const count = attempts.expiry <= currentTime ? 1 : currentCount + 1 // reset if pass 1 hour limit
      const obj = { count, expiry: currentTime + process.env.REACT_APP_SMS_ATTEMPT_LIMIT_TIME_IN_HOUR * 60 * 60 * 1000 } // 1 hour expire
      window.localStorage.setItem(attemptKey, JSON.stringify(obj))
      setInterval(() => {
        setDisableResend(false)
      }, TIME_BETWEEN_SMS_TOKEN_REQUESTS * 1000) // disabled for x amount of seconds
      const resendUser = await Auth.signIn(credentials.username, credentials.password)
      alert({ status: 'success', message: 'Verification code has been sent!' })
      setUser(resendUser)
    }
  }

  return (
    <StyledForm onSubmit={handleVerifySms}>
      <DialogContent>
        <Typography variant='body1' data-cy='verificationText'>
          A six-digit authentication code was sent to {phone}.
        </Typography>
        <Typography variant='body1' data-cy='codeNotRecievedText'>
          Didn't receive a code?{' '}
          <ResendButton onClick={handleResend} disabled={disableResend} data-cy='resendCodeButton'>
            Resend
          </ResendButton>
        </Typography>
        <FormControl variant='standard' margin='dense'>
          <InputLabel shrink data-cy='headerText'>Verification Code</InputLabel>
          <FormInput autoFocus margin='dense' size='small' value={code} variant='outlined' onChange={handleCodeChange} helperText={error} error={!!error} data-cy='verificationCodeInput' />
        </FormControl>
        <FormControlLabel
          control={
            <ColoredCheckBox
              checked={rememberDevice}
              onChange={handleRememberDevice}
              color='primary'
              name='remember'
              data-cy='rememberMeCheckbox'
            />
          }
          label='Remember this device for 30 days'
          data-cy='rememberMeLabelText'
        />
        <Typography variant='body2' color='textSecondary' data-cy='accessIssuesText'>
          Contact your Admin in case you can’t access the verification code
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' data-cy='cancelButton'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          disabled={code.length !== 6 || !!error || loading}
          data-cy='completeLoginButton'
        >
          Complete Login
        </Button>
      </DialogActions>
    </StyledForm>
  )
}
