import styled from 'styled-components'
import { Card, Grid, Button, TextField, Typography } from '@mui/material'
import { ValidationTextField, LoaderButton } from '@common'

export const Root = styled(Grid)`
  height: 100%;
  background-color: #f3f5fa;
  display: flex;
  align-items: center;
  flex-direction: column;
}
`
export const BrandingTitle = styled.div`
  background-color: ${(props) => props.theme.main};
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  & img {
    height: 48px;
    padding: 4px 0;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -200px;
  padding: 40px 0 0;
  align-items: center;
  box-sizing: border-box;
  max-width: 530px;

  @media (max-width: 600px) {
    width: 100%;
  }

  & h3 {
    color: white;
  }
`

export const FormFieldsContainer = styled(Card)`
  padding: 30px;
  margin-top: 30px;
  width: 450px;

  @media (max-width: 600px) {
    border-radius: 0;
  }

  & > form {
    margin: 16px 0;
  }

  & > form > div,
  button {
    margin: 10px 0;
  }
`

export const AuthButton = styled(LoaderButton)`
  padding: 8px 32px;
  text-transform: none;
`

export const ReturnButton = styled(Button)`
  margin-left: 0px;
  margin-top: 40px;
  padding: 8px 32px;
  text-transform: none;
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const BrandingPlaceholder = styled.div`
  padding: 7px 0 7px 32px;
  background: white;
  min-height: 56px;
`

/**
 * THis is a temprorary implementation unitl i figure out how to use media queries in styled component
 * TODO Tosin please implement this using media queries within styled comoponents
 * @mui/styled not compatible with react 18, remove it for now
 */
// export const useMediaQueries = makeStyles((theme) => ({
//   root: {
//     [theme.breakpoints.down('md')]: {
//       height: '100%',
//       padding: '0',
//       boxShadow: 'transparent',
//       flexDirection: 'column'
//     },
//     [theme.breakpoints.up('md')]: {
//       height: '90%',
//       padding: 0,
//       borderRadius: '10px',
//       flexDirection: 'row'
//     }
//   },
//   mainContainer: {
//     [theme.breakpoints.down('md')]: {
//       padding: 0
//     },
//     [theme.breakpoints.up('md')]: {
//       height: '100%',
//       padding: 0
//     }
//   },
//   leftPanel: {
//     [theme.breakpoints.down('md')]: {
//       height: '100%',
//       width: '100%'
//     },
//     [theme.breakpoints.up('md')]: {
//       height: '100%;',
//       width: '60%;'
//     }
//   },
//   rightPanel: {
//     [theme.breakpoints.down('md')]: {
//       display: 'none'
//     },
//     [theme.breakpoints.up('md')]: {
//       height: '100%;',
//       width: '40%;'
//     }
//   },
//   responsiveStyleContainer: {
//     [theme.breakpoints.up('xl')]: {
//       width: '40%'
//     },
//     [theme.breakpoints.down('xl')]: {
//       width: '40%'
//     },
//     [theme.breakpoints.down('lg')]: {
//       width: '67%'
//     },
//     [theme.breakpoints.down('md')]: {
//       width: '90%'
//     }
//   },
//   loginButton: {
//     width: '100%',
//     backgroundColor: '#2091B4'
//   }
// }))

export const EmailValidationInput = styled(ValidationTextField)`
  & input {
    text-transform: lowercase;
  }
`

export const EmailInput = styled(TextField)`
  margin: 16px 0;
  & input {
    text-transform: lowercase;
  }
`

export const TermsTypography = styled(Typography)`
  color: #707070;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  margin: 8px 0;
  cursor: pointer;
`
