import React from 'react'
import { Button, Typography, DialogActions } from '@mui/material'

import { STEPS } from '@components/authentication/constants'
import { AuthQRCode } from '@components/authentication/mfa/QRCode'
import { QRContainer, ContentText, MFAContentContainer } from '@components/authentication/mfa/styles'

export const TotpForm = ({ code, handleClose, handleStep }) => {
  const handleNextStep = () => handleStep(STEPS.VERIFY_TOTP)

  return (
    <>
      <MFAContentContainer>
        <ContentText variant='body1'>
          To enable two-factor authentication (2FA), you will need an
          authenticator app (like Authy or Google Authenticator). Once you have
          installed the app, use one of the following options.
        </ContentText>
        <Typography variant='subtitle2' align='center'>
          Scan the QR code below into the app.
        </Typography>
        <QRContainer alignContent='center'>
          <AuthQRCode secretString={code} />
        </QRContainer>
        <Typography variant='subtitle1' align='center'>
          Or, copy and paste the code below into the app.
        </Typography>
        <Typography variant='subtitle2' align='center'>
          {code}
        </Typography>
      </MFAContentContainer>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleNextStep} variant='contained' color='secondary'>
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}
