import React from 'react'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

export const ValidationTextField = ({
  fieldConfig,
  error,
  control,
  ...restProps
}) => {
  return (
    <Controller
      name={fieldConfig.name}
      control={control}
      render={({
        field: { onChange, value = '' },
        fieldState: { error },
        formState
      }) => (
        <TextField
          helperText={error && error.message}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={fieldConfig.label}
          variant='outlined'
          {...restProps}
        />
      )}
    />
  )
}
