import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useAlert } from '@hooks'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ValidationTextField } from '@common'
import { passwordPatternRegex } from '@utils'
import { useTheme } from '@theme'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Root,
  BrandingTitle,
  FormContainer,
  FormFieldsContainer,
  AuthButton,
  BrandingPlaceholder
} from './styledComponents'

const RESET_PASSWORD_SCHEMA = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password Required.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(
      passwordPatternRegex,
      'Must contain at least one uppercase, one lowercase, one number and one special case character'
    ),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
})

// V2 reset password
const ConfirmPasswordReset = () => {
  const [confirmFields, setConfirmFields] = useState({
    username: '',
    code: ''
  })
  const themeContext = useTheme()
  const { search } = useLocation()

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(RESET_PASSWORD_SCHEMA)
  })

  const alert = useAlert()
  const history = useHistory()
  const { resetPasswordV2 } = useFlags()

  // confirm token embeded
  useEffect(() => {
    const param = new window.URLSearchParams(search)
    const token = param.get('confirm_token')
    const email = param.get('email')
    if (token && email) {
      setConfirmFields({ code: token, username: decodeURIComponent(email) })
    } else {
      history.push('/')
    }
  }, [search, history])

  const handlePasswordReset = async (fields) => {
    const { username, code } = confirmFields
    const { newPassword } = fields
    const emailAddress = username.toLowerCase()

    try {
      if (resetPasswordV2) {
        // new reset password endpoint
        const CONFIRM_PASSWORD_URL = `${process.env.REACT_APP_AUTHENTICATION__URL}/confirm-password`
        await axios.post(CONFIRM_PASSWORD_URL, {
          emailAddress,
          code,
          password: newPassword
        })
      } else {
        await Auth.forgotPasswordSubmit(emailAddress, code, newPassword)
      }
      alert({
        message: 'Password has been reset!',
        status: 'success'
      })
      history.push('/')
    } catch (error) {
      alert({
        message:
          'The reset link has been expired, please request a link again.',
        status: 'error'
      })
    }
  }

  return (
    <Root>
      <BrandingTitle>
        <BrandingPlaceholder>
          {themeContext.logo
            ? (
              <img src={themeContext.logo} alt={themeContext.displayName || ''} />
              )
            : (
              <Typography variant='h5'>
                {themeContext.displayName || ''}
              </Typography>
              )}
        </BrandingPlaceholder>
      </BrandingTitle>

      <FormContainer>
        <FormFieldsContainer>
          <Typography>Enter a new password</Typography>
          <form onSubmit={handleSubmit(handlePasswordReset)}>
            <ValidationTextField
              fieldConfig={{ name: 'newPassword', label: 'New Password' }}
              control={control}
              type='password'
              error={errors.newPassword}
              data-cy='newPasswordInput'
              id='newPasswordInput'
            />
            <ValidationTextField
              fieldConfig={{
                name: 'confirmedPassword',
                label: 'Confirm Password'
              }}
              control={control}
              type='password'
              error={errors.confirmedPassword}
              data-cy='confirmPasswordInput'
              id='confirmPasswordInput'
            />
            <AuthButton
              variant='contained'
              color='secondary'
              fullWidth
              type='submit'
            >
              Save Password
            </AuthButton>
          </form>
        </FormFieldsContainer>
      </FormContainer>
    </Root>
  )
}

export default ConfirmPasswordReset
