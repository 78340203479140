import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import { logger } from '@utils'

export default class SentryBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { error: null, eventId: null }
  }

  // use this method to trigger a render of the fallback UI
  static getDerivedStateFromError (error) {
    return { error }
  }

  componentDidMount () {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN
      })
    } else {
      logger.info('Sentry.io for auth-ui disconnected')
    }
  }

  componentDidCatch (error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  // asks the user to submit an error report
  renderDialog () {
    return Sentry.showReportDialog({ eventId: this.state.eventId })
  }

  render () {
    if (this.state.error) {
      return (
        <div>
          {this.renderDialog()}
          <p>We're sorry — something's gone wrong.</p>
          <p>Our team has been notified, but you can fill out a report.</p>
        </div>
      )
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}
