import { IconButton, Toolbar, DialogTitle, AppBar } from '@mui/material'
import styled from 'styled-components'

export const ModalToolbarContainer = styled(Toolbar)`
  padding: 0;

  & > div,
  button {
    padding: 0;
  }
`

export const ModalTitleContainer = styled(DialogTitle)`
  flex-grow: 1;
`
export const ModalTopbar = styled(AppBar)`
  position: relative;
  background-color: white;
  color: black;
  padding: 0 24px;
`

export const StyledIconButton = styled(IconButton)`
  margin-right: 15px;
`
