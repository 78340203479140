import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { isDomainAllowed } from '@utils'

const InterceptRedirectRoutes = () => {
  const location = useLocation()
  const { search, pathname } = location
  const { href } = window.location
  let redirectPath = ''
  const params = new URLSearchParams()
  params.set('continue', href)
  const domainIsSafe = isDomainAllowed(href)
  if (search && domainIsSafe) {
    const searchParams = new URLSearchParams(search)
    if (!searchParams.has('continue')) {
      redirectPath = `?${params.toString()}`
    } else {
      redirectPath = `?${searchParams.toString()}`
    }
  } else if (pathname !== '/') {
    redirectPath = `?${params.toString()}`
  }
  return (
    <Redirect
      to={{
        pathname: '/',
        search: redirectPath
      }}
    />
  )
}

export default InterceptRedirectRoutes
