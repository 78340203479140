import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import {
  Button,
  Typography,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel
} from '@mui/material'
import { useAlert } from '@hooks'
import { FormInput, StyledForm } from '@components/authentication/mfa/styles'
import { setDeviceKeyExpiration } from '@utils/deviceKey'

export const TOTPLoginForm = ({
  user,
  handleClose,
  handleSuccessLogin
}) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [rememberDevice, setRememberDevice] = useState(false)
  const alert = useAlert()

  const handleCodeChange = (e) => {
    setError('') // clear out the error message
    const value = e.target.value.replace(/[^0-9]/g, '')
    if (value.length <= 6) setCode(value)
  }
  const handleRememberDevice = (e) => setRememberDevice(e.target.checked)

  const handleVerifyTotp = async (e) => {
    if (loading) return // prevent double click
    setLoading(true)
    e.preventDefault()
    try {
      await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
      if (rememberDevice) {
        await Auth.rememberDevice()
      }
      alert({ status: 'success', message: 'Successful login, welcome!' })
      setDeviceKeyExpiration(user)
      handleSuccessLogin()
    } catch (error) {
      setLoading(false)
      setError('The code is incorrect or expired. Verify the code and try again.')
    }
  }

  return (
    <StyledForm onSubmit={handleVerifyTotp}>
      <DialogContent>
        <Typography variant='body1' data-cy='verificationText'>
          Enter the six-digit verification code you see in the authenticator app
        </Typography>
        <FormControl variant='standard' margin='dense'>
          <InputLabel shrink data-cy='headerText'>Verification Code</InputLabel>
          <FormInput autoFocus margin='dense' size='small' variant='outlined' value={code} onChange={handleCodeChange} helperText={error} error={!!error} data-cy='verificationCodeInput' />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberDevice}
              onChange={handleRememberDevice}
              name='remember'
              data-cy='rememberMeCheckbox'
            />
          }
          label='Remember this device for 30 days'
          data-cy='rememberMeLabelText'
        />
        <Typography variant='body2' color='textSecondary' data-cy='contactAdminText'>
          Contact your Admin in case you can’t access the verification code
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' data-cy='cancelButton'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          data-cy='completeLoginButton'
          disabled={code.length !== 6 || !!error || loading}
        >
          Complete Login
        </Button>
      </DialogActions>
    </StyledForm>
  )
}
