import React from 'react'

import { ModalTitle } from '@common'
import { SMSLoginForm, TOTPLoginForm } from '@components/authentication/mfa'
import { MFAModalContainer } from '@components/authentication/mfa/styles'
import { CHALLENGE_NAMES } from '@components/authentication/constants'

export const MfaLoginModal = ({
  open,
  user,
  credentials,
  handleModalOpen,
  handleSuccessLogin,
  setUser
}) => {
  const method = user?.challengeName
  const handleClose = () => handleModalOpen(false)

  return (
    <MFAModalContainer
      open={open}
      onClose={handleModalOpen}
      disableBackdropClick
    >
      <ModalTitle title='Verification Code' handleModalToggle={handleModalOpen} />
      {method === CHALLENGE_NAMES.SMS_MFA && (
        <SMSLoginForm
          user={user}
          handleClose={handleClose}
          handleSuccessLogin={handleSuccessLogin}
          credentials={credentials}
          setUser={setUser}
        />
      )}
      {method === CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA && (
        <TOTPLoginForm
          user={user}
          handleClose={handleClose}
          handleSuccessLogin={handleSuccessLogin}
        />
      )}
    </MFAModalContainer>
  )
}
