import React from 'react'
import { CircularProgress, Button } from '@mui/material'
import { LoaderContainer, Circle, Loader } from './Loader.style'
import styled from 'styled-components'
import Skeleton from '@mui/material/Skeleton'

const AppLoader = () => (
  <Loader>
    <LoaderContainer>
      <Circle />
      <Circle />
      <Circle />
    </LoaderContainer>
  </Loader>
)

const LoadContainer = styled.div`
  width: 100%;
  height: auto;
`

const ListLoader = () => (
  <LoadContainer>
    <Skeleton />
    <Skeleton animation={false} />
    <Skeleton />
    <Skeleton animation='wave' />
    <Skeleton animation='wave' />
  </LoadContainer>
)

const LoaderButton = ({
  loading,
  label,
  children,
  iconSize = 24,
  ...restProps
}) => {
  return (
    <Button {...restProps} disabled={loading}>
      {loading ? <CircularProgress size={iconSize} color='primary' /> : children}
    </Button>
  )
}

export { AppLoader, ListLoader, LoaderButton }
