import React from 'react'
import { IMaskInput } from 'react-imask'
import { TextField } from '@mui/material'

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask='(#00) 000-0000'
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

export const PhoneInput = ({ value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <TextField
      margin='dense'
      size='small'
      variant='outlined'
      value={value}
      onChange={handleChange}
      name='numberformat'
      InputProps={{
        inputComponent: TextMaskCustom
      }}
      {...props}
    />
  )
}
