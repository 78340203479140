import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { InterceptRedirectRoutes } from '@common'
import { Login, ResetPassword, ConfirmPasswordReset, ReinvitePage } from './components/authentication'

export default function Routes ({ appProps }) {
  return (
    <Switch>
      <Route path='/' exact render={(props) => <Login {...props} {...appProps} />} />
      <Route
        path='/reset-password'
        exact
        render={(props) => <ResetPassword {...props} {...appProps} />}
      />
      <Route
        path='/confirm-password'
        exact
        render={(props) => <ConfirmPasswordReset {...props} {...appProps} />}
      />
      <Route
        path='/re-invite'
        exact
        render={(props) => <ReinvitePage {...props} {...appProps} />}
      />
      <InterceptRedirectRoutes path='**' appProps={appProps} />
      <Redirect to='/' />
    </Switch>
  )
}
