import React from 'react'
import { useForm } from 'react-hook-form'
import { AuthButton } from './styledComponents'
import { Auth } from 'aws-amplify'
import { useTheme } from '@theme'

export const SAMLLoginForm = () => {
  const { samlProvider, logo, displayName } = useTheme()
  const { handleSubmit } = useForm({})

  const handleLogin = async () => {
    Auth.federatedSignIn({ provider: samlProvider })
  }

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <AuthButton
        fullWidth
        variant='outlined'
        color='info'
        type='submit'
        startIcon={logo ? <img src={logo} alt='parter-logo' height={18} /> : null}
      >
        Log In with {displayName}
      </AuthButton>
    </form>
  )
}
