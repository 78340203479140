import React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { AuthButton } from './styledComponents'
import { ValidationTextField } from '@common'
import { passwordPatternRegex } from '@utils'

const NEW_PASSWORD_SCHEMA = yup.object().shape({
  new_password: yup
    .string()
    .required('Password Required.')
    .min(8, 'Password is too short - should be 8 characters minimum.')
    .matches(
      passwordPatternRegex,
      'Must contain at least one uppercase, one lowercase, one number and one special case character'
    ),
  new_confirmed_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
})

export const NewPasswordRequiredForm = ({ completeNewPassword }) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(NEW_PASSWORD_SCHEMA)
  })
  const { forceMfaSetup } = useFlags()

  const handlePassword = (user) => {
    completeNewPassword(user)
  }

  return (
    <form onSubmit={handleSubmit(handlePassword)}>
      <ValidationTextField
        fieldConfig={{ name: 'new_password', label: 'Password' }}
        control={control}
        type='password'
        error={errors.new_password}
        data-cy='newPasswordInput'
      />
      <ValidationTextField
        fieldConfig={{
          name: 'new_confirmed_password',
          label: 'Confirm Password'
        }}
        control={control}
        type='password'
        error={errors.new_confirmed_password}
        data-cy='confirmPasswordInput'
      />
      <ValidationTextField
        fieldConfig={{ name: 'first_name', label: 'First Name' }}
        control={control}
        error={errors.first_name}
        data-cy='firstNameInput'
      />
      <ValidationTextField
        fieldConfig={{ name: 'last_name', label: 'Last Name' }}
        control={control}
        error={errors.last_name}
        data-cy='lastNameInput'
      />
      <AuthButton
        variant='contained'
        color='secondary'
        fullWidth
        type='submit'
        data-cy='MFASetupButton'
      >
        {forceMfaSetup ? 'Set Up Two-Factor Authentication' : 'Log In'}
      </AuthButton>
    </form>
  )
}
