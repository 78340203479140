import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import {
  Button,
  DialogActions,
  FormControl,
  InputLabel
} from '@mui/material'

import { STEPS } from '@components/authentication/constants'
import { CountryCodeSelect } from '@components/authentication/mfa'
import { ContentText, PhoneFormInput, MFAContentContainer, StyledForm } from '@components/authentication/mfa/styles'
import { revertPhoneFormat, logger } from '@utils'

export const SMSForm = ({ user, handleClose, handleStep }) => {
  const [phone, setPhone] = useState('')
  const [area, setArea] = useState('1')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handlePhoneChange = (value) => setPhone(value)
  const handleCountrySelect = (e, value) => setArea(value.phone)

  const handlePhoneNumberSetup = async (e) => {
    if (loading) return
    setLoading(true)
    e.preventDefault()
    const phoneNumber = revertPhoneFormat(phone)
    try {
      await Auth.updateUserAttributes(user, {
        phone_number: `+${area}${phoneNumber}`
      })
      await Auth.verifyUserAttribute(user, 'phone_number')
      handleStep(STEPS.VERIFY_SMS)
    } catch (error) {
      setLoading(false)
      const newError = new Error(`Setup SMS error - ${user.emailAddress} - ${error.message}`)
      logger.error(newError)
      setError('The phone number is invalid. Enter a valid phone number and try again.')
    }
  }

  return (
    <StyledForm onSubmit={handlePhoneNumberSetup}>
      <MFAContentContainer>
        <ContentText variant='body1'>
          Enter your mobile phone number. We’ll send a six-digit verification
          code to your phone.
        </ContentText>
        <div>
          <CountryCodeSelect handleSelect={handleCountrySelect} />
        </div>
        <div>
          <FormControl variant='standard' margin='dense'>
            <InputLabel shrink>Phone number</InputLabel>
            <PhoneFormInput value={phone} onChange={handlePhoneChange} helperText={error} error={!!error} />
          </FormControl>
        </div>
      </MFAContentContainer>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          disabled={revertPhoneFormat(phone).length !== 10 || loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledForm>
  )
}
