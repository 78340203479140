import React from 'react'
import { TextField, FormControl, InputLabel } from '@mui/material'
import { CountrySelectContainer } from '@components/authentication/mfa/styles'

export const CountryCodeSelect = ({ handleSelect }) => {
  return (
    <FormControl variant='standard' margin='dense'>
      <InputLabel shrink>Country</InputLabel>
      <CountrySelectContainer
        options={countries}
        autoHighlight
        getOptionLabel={(option) => `${option.label}, ${option.code}, +${option.phone}`}
        onChange={handleSelect}
        getOptionDisabled={(option) => option.code !== 'US'}
        defaultValue={{ code: 'US', label: 'United States', phone: '1' }}
        renderInput={(params) => <TextField {...params} margin='dense' size='small' variant='outlined' />}
      />
    </FormControl>
  )
}

const countries = [
  { code: 'US', label: 'United States', phone: '1' }
]
