import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import {
  Button,
  Typography,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel
} from '@mui/material'
import { useAlert } from '@hooks'
import { FormInput, ResendButton, StyledForm } from '@components/authentication/mfa/styles'
import { formatPhoneNumber, logger } from '@utils'
import { RESEND_ATTEMPT_KEY_SUFFIX } from '@components/authentication/constants'
import { setDeviceKeyExpiration } from '@utils/deviceKey'

const TIME_BETWEEN_SMS_TOKEN_REQUESTS = process.env.REACT_APP_TIME_BETWEEN_SMS_TOKEN_REQUESTS

export const VerifySMSForm = ({
  user,
  handleClose,
  handleSuccessLogin
}) => {
  const attemptKey = `${user.username}-${RESEND_ATTEMPT_KEY_SUFFIX}`
  const attempts = JSON.parse(window.localStorage.getItem(attemptKey)) || { count: 0 }
  const isAttemptLimited = attempts.count >= 6 && attempts.expiry > new Date().getTime() // initial state for resend button

  const [code, setCode] = useState('')
  const [phone, setPhone] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [disableResend, setDisableResend] = useState(isAttemptLimited)
  const alert = useAlert()

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await Auth.userAttributes(user)
      const phone = userInfo.find(info => info.Name === 'phone_number')
      setPhone(phone.Value)
    }
    fetchUserInfo()
  }, [user])

  const handleCodeChange = (e) => {
    setError('') // clear out the error message
    const value = e.target.value.replace(/[^0-9]/g, '')
    if (value.length <= 6) setCode(value)
  }

  const handleVerifySms = async (e) => {
    if (loading) return
    setLoading(true)
    e.preventDefault()
    try {
      const response = await Auth.verifyUserAttributeSubmit(user, 'phone_number', code)
      if (response === 'SUCCESS') {
        await Auth.setPreferredMFA(user, 'SMS')
        alert({ status: 'success', message: 'Successful login, welcome!' })
        setDeviceKeyExpiration(user)
        handleSuccessLogin()
      }
    } catch (error) {
      setLoading(false)
      const newError = new Error(`Login SMS error - ${user.emailAddress} - ${error.message}`)
      logger.error(newError)
      setError('The code is incorrect or expired. Verify the code and try again.')
    }
  }

  const handleResend = async () => {
    setDisableResend(true)
    const currentCount = attempts.count
    const currentTime = new Date().getTime()

    if (currentCount >= process.env.REACT_APP_SMS_ATTEMPT_LIMIT && attempts.expiry > currentTime) {
      alert({ status: 'error', message: 'You have reached the maximum number of code requests. Please try again in one hour.' })
    } else {
      const count = attempts.expiry <= currentTime ? 1 : currentCount + 1 // reset if pass 1 hour limit
      const obj = { count, expiry: currentTime + process.env.REACT_APP_SMS_ATTEMPT_LIMIT_TIME_IN_HOUR * 60 * 60 * 1000 } // 1 hour expire
      window.localStorage.setItem(attemptKey, JSON.stringify(obj))
      setInterval(() => {
        setDisableResend(false)
      }, TIME_BETWEEN_SMS_TOKEN_REQUESTS * 1000) // disabled for x amount of seconds
      // resend the verification code
      try {
        await Auth.updateUserAttributes(user, {
          phone_number: phone
        })
        alert({ status: 'success', message: 'Verification code has been sent!' })
        await Auth.verifyUserAttribute(user, 'phone_number')
      } catch (error) {
        const newError = new Error(`Resend SMS error - ${user.emailAddress} - ${error.message}`)
        logger.error(newError)
        alert({ message: 'Unable to resend the code, please try again', status: 'error' })
      }
    }
  }

  return (
    <StyledForm onSubmit={handleVerifySms}>
      <DialogContent>
        <Typography variant='body1' data-cy='enterPhoneNumberText'>
          Enter the six-digit verification code we sent to your mobile phone.
        </Typography>
        <FormControl variant='standard' margin='dense'>
          <InputLabel shrink data-cy='headerText'>Verification Code</InputLabel>
          <FormInput autoFocus margin='dense' value={code} size='small' variant='outlined' onChange={handleCodeChange} helperText={error} error={!!error} data-cy='verificationCodeInput' />
        </FormControl>
        <Typography variant='body1' data-cy='verificationText'>
          A six-digit authentication code was sent to {formatPhoneNumber(phone)}.
        </Typography>
        <Typography variant='body1' data-cy='codeNotRecievedText'>
          Didn't receive a code?{' '}
          <ResendButton onClick={handleResend} data-cy='resendCodeButton' disabled={disableResend}>
            Resend
          </ResendButton>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' data-cy='backButton'>
          Back
        </Button>
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          data-cy='confirmCodeButton'
          disabled={code.length !== 6 || !!error || loading}
        >
          Confirm Code
        </Button>
      </DialogActions>
    </StyledForm>
  )
}
