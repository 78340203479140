import { useState } from 'react'
import { Typography } from '@mui/material'
import axios from 'axios'
import { useAlert } from '@hooks'

import {
  FormContainer,
  FormFieldsContainer,
  AuthButton,
  EmailInput,
  Root,
  BrandingTitle,
  BrandingPlaceholder
} from './styledComponents'
import { useTheme } from '@theme'
import { useFlags } from 'launchdarkly-react-client-sdk'

const ReinvitePage = () => {
  const params = new URLSearchParams(window.location.search)
  const email = params.get('email')
  const [emailAddress, setemailAddress] = useState(email)
  const themeContext = useTheme()
  const alert = useAlert()
  const { selfResendInvitation } = useFlags()

  const handleReinvitation = () => {
    const handleResendInvitation = async () => {
      try {
        const selfReinviteUrl = `${process.env.REACT_APP_AUTHENTICATION__URL}/resend-invitation`
        await axios.post(selfReinviteUrl, {
          emailAddress: emailAddress.toLowerCase()
        })
        alert({ message: 'User successfully re-invited. Please check your email for the new invite.', status: 'success' })
      } catch (error) {
        alert({ message: error.message, status: 'error' })
      }
    }
    handleResendInvitation()
  }

  const handleEmailChange = (e) => {
    setemailAddress(e.target.value)
  }

  return (
    <Root>
      <BrandingTitle>
        <BrandingPlaceholder>
          {themeContext.logo
            ? (
              <img src={themeContext.logo} alt={themeContext.displayName || ''} />
              )
            : (
              <Typography variant='h5'>
                {themeContext.displayName || ''}
              </Typography>
              )}
        </BrandingPlaceholder>s
      </BrandingTitle>

      <FormContainer>
        <FormFieldsContainer>
          <Typography>
            The activation link has expired. Please click to resend the invite.
          </Typography>
          <EmailInput
            fullWidth
            label='Email Address'
            id='usernameInput'
            variant='outlined'
            data-cy='usernameInput'
            defaultValue={emailAddress}
            onChange={handleEmailChange}
          />
          <AuthButton
            variant='contained'
            color='secondary'
            fullWidth
            disabled={!selfResendInvitation}
            onClick={handleReinvitation}
          >
            Resend Invite
          </AuthButton>
        </FormFieldsContainer>
      </FormContainer>
    </Root>
  )
}

export default ReinvitePage
