import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import Routes from '@src/Routes'
import { AppLoader } from '@common'
import { AlertContextProvider } from '@hooks'
import { AppThemeProvider } from '@theme'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

const ldConfig = {
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY
}

const App = (props) => {
  const [user, setUser] = useState(null)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const { search } = useLocation()
  const history = useHistory()

  useEffect(() => {
    const configureUserCredentials = async () => {
      setIsAuthenticating(true)
      try {
        const session = await Auth.currentSession()
        if (session) userHasAuthenticated(true)
      } catch (error) {
        userHasAuthenticated(false)
      }
      setIsAuthenticating(false)
    }
    configureUserCredentials()
  }, [isAuthenticated])

  useEffect(() => {
    const param = new window.URLSearchParams(search)
    const emailAddresss = param.get('email')
    const password = param.get('token')
    const handleTokenLogin = async () => {
      setIsAuthenticating(true)
      try {
        const user = await Auth.signIn(
          decodeURIComponent(emailAddresss),
          decodeURIComponent(password)
        )
        setUser(user)
      } catch (error) {
        const newError = new Error(
          `new invited user error with ${emailAddresss},  message: ${error.message}`
        )
        Sentry.captureException(newError)
        history.push({ pathname: '/re-invite', search: `?email=${encodeURIComponent(emailAddresss)}` })
        setUser(null)
      } finally {
        setIsAuthenticating(false)
      }
    }
    if (emailAddresss && password) handleTokenLogin()
    // disable the 'history' dependency warning, cause it will change frequently, but only need to run once
    // eslint-disable-next-line
  }, [search])

  if (isAuthenticating) return <AppLoader />

  return (
    <AppThemeProvider>
      <AlertContextProvider>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated, user }} />
      </AlertContextProvider>
    </AppThemeProvider>
  )
}

export default withLDProvider(ldConfig)(App)
