import {
  Typography,
  Dialog,
  DialogContent,
  TextField,
  Checkbox,
  Button,
  FormGroup,
  Autocomplete
} from '@mui/material'
import styled from 'styled-components'
import { PhoneInput } from '@common'

export const QRContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 8px 0;
`

export const ContentText = styled(Typography)`
  /* margin: 40px 0 30px; */
`

export const MFAModalContainer = styled(Dialog)`
  & > .MuiDialog-container > .MuiDialog-paper {
    min-height: 400px;
  }
`

export const MFAFormContent = styled(DialogContent)`
  flex: 1;
`

export const SelectContent = styled(DialogContent)`
  flex: 0 auto;
  overflow-y: unset;

  & .MuiRadio-colorPrimary.Mui-checked {
    color: #1182ac;
  }
`

export const FormInput = styled(TextField)`
  width: 300px;
  margin: 16px 0;
`

export const PhoneFormInput = styled(PhoneInput)`
  width: 300px;
  margin: 16px 0;
`

export const CountrySelectContainer = styled(Autocomplete)`
  width: 300px;
  margin: 8px 0;
`

export const ColoredCheckBox = styled(Checkbox)`
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #1182ac;
  }
`

export const MFAContentContainer = styled(DialogContent)`
  min-height: 400px;

  & > p,
  h6 {
    margin: 20px 0;
  }
`

export const ResendButton = styled(Button)`
  color: #1182ac;
`

export const MFAMethodSelectContainer = styled(FormGroup)`
  margin: 16px 0 0;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
