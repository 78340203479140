import React, { useState, createContext, useContext } from 'react'
import { Snackbar } from '@mui/material'

import MuiAlert from '@mui/material/Alert'

const AlertContext = createContext({})

const alertConfig = {
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  autoHideDuration: 15000,
  style: { minWidth: 320, maxWidth: 700 }
}

export const AlertContextProvider = ({ children }) => {
  const [alertStates, setAlertStates] = useState({
    open: false,
    message: '',
    status: 'success'
  })
  const handleAlert = ({ message, status, onClose }) =>
    setAlertStates({ open: true, message, status, onClose })

  return (
    <AlertContext.Provider value={handleAlert}>
      {children}
      <Snackbar
        {...alertConfig}
        open={alertStates.open}
        onClose={(e, reason) => {
          if (alertStates.onClose) alertStates.onClose()
          if (reason === 'timeout') setAlertStates({ open: false })
        }}
      >
        <div>
          <MuiAlert
            severity={alertStates.status}
            elevation={6}
            variant='filled'
          >
            {alertStates.message}
          </MuiAlert>
        </div>
      </Snackbar>
    </AlertContext.Provider>
  )
}

export const useAlert = () => useContext(AlertContext)
