export const CHALLENGE_NAMES = {
  SMS_MFA: 'SMS_MFA',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP: 'MFA_SETUP'
}

export const MFA_METHODS = {
  TOTP: 'totp',
  SMS: 'sms'
}

export const STEPS = {
  METHOD_SELECT: 'methodSelect',
  VERIFY_TOTP: 'verifyTOTP',
  VERIFY_SMS: 'verifySMS'
}

export const authenticatorURI = (secret) =>
`otpauth://totp/firm.ai?secret=${secret}&issuer=Portal`

export const EMPLOYEE_EMAIL_REGEX = /@(botkeeper|thefutureofbookkeeping)\.com$/i

export const RESEND_ATTEMPT_KEY_SUFFIX = 'resend-attempts'
