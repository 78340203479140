import { logger } from '@utils'

const DOMAIN_WHITELIST = process.env.REACT_APP_PARTNER_PORTAL
const NODE_ENV = process.env.NODE_ENV

export const isDomainAllowed = (domain) => {
  if (!domain) {
    return
  }
  try {
    const domainName = new URL(domain).hostname
    const appDomainName = NODE_ENV !== 'production' ? DOMAIN_WHITELIST : window.location.hostname
    return domainName === appDomainName
  } catch (err) {
    logger.error(err.message)
    return null
  }
}
